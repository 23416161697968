<template>
  <InfoWraper>
    <div class="nav-author">
        <a href="/Analytics User Guide.pdf" style="margin-bottom: 5px; font-size: 18px; padding-right: 15px; color: #1f2120;" download> User Guide
        </a>
    </div>
      <!--<a-switch 
      v-model:checked="isConnect" 
      checked-children="Disconnect" 
      un-checked-children="Connect" 
      @change="handleSwitchChange"
      class="custom-switch"
    />-->
    <div class="page-header-actions">
        <a-button size="small" v-if="disConnect" type="primary" v-on:click="xeroconnect()">
         Connect 
        </a-button> 
      </div>
      <div class="page-header-actions" style="margin-left: 10px !important;">
        <a-button size="small" type="primary" v-if="disConnect"  v-on:click="xerodisconnect()">
         Bulk Disconnect 
        </a-button> 
      </div>
      
    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click" >
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info"> 
                <img
                  v-bind:src="currentuser.profile_pic"
                  alt=""
                  style="height: 50px; width: 50px"
                  v-if="currentuser != null"
                />
                <figcaption>
                  <sdHeading as="h5">{{
                    currentuser.f_name + " " + currentuser.l_name
                  }}</sdHeading>
                  <p>{{ currentuser.email }}</p>
                </figcaption>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <a to="profile" href="profile"> <sdFeatherIcons type="user" /> Profile </a>
                </li>
                <li>
                <a to="profile" target="_blank" href="https://login.xero.com/identity/user/login"> <sdFeatherIcons type="arrow-up-right" /> Sign up with Xero </a>
                </li>
                <!-- <li>
                  <a to="#"> <sdFeatherIcons type="settings" /> Settings </a>
                </li>
                <li>
                  <a to="#"> <sdFeatherIcons type="dollar-sign" /> Billing </a>
                </li>
                <li>
                  <a to="#"> <sdFeatherIcons type="users" /> Activity </a>
                </li>
                <li>
                  <a to="#"> <sdFeatherIcons type="bell" /> Help </a>
                </li> -->
              </ul>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#">
                <LogoutOutlined /> Sign Out
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar
            v-if="currentuser != null"
            v-bind:src="API_ENDPOINT + currentuser.profile_pic"
            style="height: 40px; width: 40px"
          />
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { LogoutOutlined } from "@ant-design/icons-vue";
import { computed, ref, defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "AuthInfo",
  props: ["currentuser","disConnect"],
  watch: {
    currentuser: function (newVal, oldVal) {
      // watch it
     
    },
  },
  components: {
    InfoWraper,
    UserDropDwon,
    LogoutOutlined,
  },
  data() {
    return {
      flag: "english",
    };
  },
  setup(props) {
    const { dispatch } = useStore();
    const { push } = useRouter();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    var isConnect = ref(true);
     
    const SignOut = (e) => {
      e.preventDefault();
      dispatch("logOut").then(() => {
        push("/auth/login");
      });
    };
    const handleSwitchChange = (checked) => {
      isConnect.value = checked;
      if (checked) {
        // Logic when switching to Disconnect
        alert("disconnected");
        xerodisconnect();
      } else {
        // Logic when switching to Connect
        alert("connected");
        xeroconnect();
      }
    };
    const xeroconnect = () => {
      //alert("In Connected");
      window.location.href = API_ENDPOINT + 'xero/authorization.php?app_id=1';
    };
    const xerodisconnect = () => {
      //alert("In DisConnected");
      window.location.href = API_ENDPOINT + 'xero/authorization1.php?app_id=1';
    };

    return {
      SignOut,
      API_ENDPOINT,
      xeroconnect,
      xerodisconnect,
      handleSwitchChange,
      isConnect,
      };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
});
</script>
<style scoped>
.custom-switch {
  width: 120px; /* Adjust the width */
  height: 34px; /* Adjust the height */
}
.custom-connect {
  background-color: #52c41a; /* Green color for Connect */
}

.custom-disconnect {
  background-color: #f5222d; /* Red color for Disconnect */
}
.custom-switch.ant-switch::after {
  width: 30px !important; /* Adjust the handle size */
  height: 30px !important; /* Adjust the handle size */
  top: 1px; /* Adjust the position */
  left:1px;
}

.custom-switch.ant-switch-checked.ant-switch::after {
  left: calc(100% - 1px); /* Adjust the handle position when checked */
}

.custom-switch .ant-switch-inner {
  line-height: 34px !important; /* Adjust the inner content alignment */
}
</style>