<template>
  <Div class="certain-category-search-wrapper" :style="{ width: '100%', border: 'none !important'}" :darkMode="darkMode">
    <a-row class="ant-row-middle">
      <a-col :md="1" :xs="1" :class="rtl ? 'text-left' : 'text-right'">
      </a-col>
      <a-col :md="22" :xs="23">
        <!--<a-input placeholder="Search..." @input="(e) => search(e, searchData)" />-->
        <span class="certain-category-icon" style="padding: 10px 10px 10px 10px; border: solid 1px #ddd">
        <sdFeatherIcons type="search" />
        <a-input
        v-model:value="addFormState.search_text"
                placeholder="Search..."
                @change="handleSearch"
                style="padding: 0px 0px 0px 0px !important; background-color: #fff; color: #000 !important;"/>
        </span>
        <!-- <sdPopover
          :placement="!rtl ? 'bottomLeft' : 'bottomRight'"
          v-model="visible"
          title="Search List"
          action="click"
        >
          <template v-slot:content>
            <div> 
                <span class="certain-search-item-count">item</span> 
            </div>
          </template>
          <a-input placeholder="Search..." @input="(e) => search(e, searchData)" />
        </sdPopover> -->
      </a-col>
    </a-row>
  </Div>
</template>

<script>
import { Div } from './header-search-style';
import { useStore } from 'vuex';
import { computed, ref, defineComponent,reactive } from 'vue';

export default defineComponent({
  name: 'HeaderSearch',
  props: ['darkMode'],
  components: {
    Div,
  },
  setup() {
    const { state } = useStore();
    const searchData = ref([
      {
        id: 1,
        title: 'AntDesign',
        count: 10000,
      },
      {
        id: 2,
        title: 'Design UI',
        count: 10600,
      },

      {
        id: 3,
        title: 'Bootstrap Design UI',
        count: 60100,
      },
      {
        id: 4,
        title: 'Meterial design',
        count: 30010,
      },

      {
        id: 5,
        title: 'AntDesign design language',
        count: 100000,
      },
    ]);
    const rtl = computed(() => state.themeLayout.rtlData);
    const filteredData = ref([
      {
        id: 1,
        title: 'AntDesign',
        count: 10000,
      },
      {
        id: 2,
        title: 'Design UI',
        count: 10600,
      },

      {
        id: 3,
        title: 'Bootstrap Design UI',
        count: 60100,
      },
      {
        id: 4,
        title: 'Meterial design',
        count: 30010,
      },

      {
        id: 5,
        title: 'AntDesign design language',
        count: 100000,
      },
    ]);
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });

    const rules = {
      search_text: [
        {
          required: true,
          message: "Search Here....",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: true,
          message: "Please Select a valid Option!",
          trigger: "blur",
        },
      ],
    };
    const handleSearch = (values) => {
      let substring = addFormState.search_text;
      state.xeroclient.data = JSON.parse(localStorage.getItem("search_data"));
      let xero_get_datas = [];
      (JSON.parse(localStorage.getItem("search_data"))).map((data,index) => {
        let search_match = false;
        search_match = (((data.client_name).toLowerCase()).includes(substring.toLowerCase()));
        //search_match = (((data.client_name)).includes(substring));
        if(search_match == false){search_match = (((data.tenent_id)).includes(substring));
        }
        if(search_match == false){
          (state.xeroclient.data).splice(index, 1);
        }else{
          xero_get_datas.push(JSON.parse(localStorage.getItem("search_data"))[index])
        }
      });
      //console.log(xero_get_datas);
      state.xeroclient.data = xero_get_datas;
    };
    const visible = ref(false);

    const search = (e, searchDatas) => {
      let search_text = (((e.target.value).replace(/ +/g, "")).replace(/[^a-zA-Z ]/g, "").toLowerCase());
      searchDatas = JSON.parse(localStorage.getItem("search_data"));
      const data = searchDatas.filter((item) => {
        if (item.client_name) {
          return (((item.client_name).replace(/ +/g, "")).replace(/[^a-zA-Z ]/g, "").toLowerCase()).startsWith(search_text);
        }
      });
    
      localStorage.setItem("filter_data",JSON.stringify(data));
      // console.log(data);
      // filteredData.value = data;
    };
    const hide = () => {
      visible.value = false;
    };

    return {
      search,
      hide,
      searchData,
      filteredData,
      visible,
      rtl,
      addFormState,
      handleSearch,
    };
  },
});
</script>
